import React from 'react'
import { Link } from 'gatsby'
import Image from 'gatsby-image'

export default function PhotosetItem({ photoset }) {
  return (
    <div className="photoset-item">
      <Link to={`/photosets/${photoset.slug}`}>
        <div className="link-text-hover">
          <Image
            className="cover"
            fixed={photoset.cover.localFile.childImageSharp.fixed}
          />
          <div className="title">
            <h3>{photoset.title}</h3>
          </div>
        </div>
      </Link>
    </div>
  )
}
