import React from 'react'
import PhotosetItem from './PhotosetItem'

export default function PhotosetListing({ photosets }) {
  return (
    <div className="photoset-listing">
      {photosets.edges.map((photoset, index) => {
        return <PhotosetItem key={index} photoset={photoset.node} />
      })}
    </div>
  )
}
