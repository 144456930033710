const config = {
  siteTitle: 'Air Pawikorn',
  siteTitleShort: 'Air Pawikorn',
  siteTitleAlt: 'Air Pawikorn',
  siteLogo: '/logos/logo-1024.png',
  siteUrl: 'https://airji.ohmpiromrak.com',
  siteDescription: `I'm a medical intern. My website about photosets, journey blogs, and stuff.`,
  googleAnalyticsID: 'UA-150973606-1',
  postDefaultCategoryID: 'Tech',
  dateFromFormat: 'YYYY-MM-DD',
  dateFormat: 'MMMM Do, YYYY',
  userDescription: `I'm a medical intern. My website about photosets, journey blogs, and stuff.`,
  themeColor: '#c62828',
  backgroundColor: '#e0e0e0',
  contentfulSpaceId: 'ow8uygewks8c',
  contentfulAccessToken: '25R1LcKQvJ_skxQB5KwX1gFwGXniGZcYow2wEl_yLIQ',
  menuLinks: [
    {
      name: 'Me',
      link: '/',
    },
    {
      name: 'Photosets',
      link: '/photosets',
    },
  ],
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/')
  config.siteUrl = config.siteUrl.slice(0, -1)

module.exports = config
