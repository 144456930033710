import React from 'react'
import Helmet from 'react-helmet'
import config from '../../data/SiteConfig'
import urljoin from 'url-join'

import { useStaticQuery, graphql } from 'gatsby'

export default function SEO({ title, description, image, slug }) {
  const { profilePhoto } = useStaticQuery(
    graphql`
      query seoQuery {
        profilePhoto: file(absolutePath: { regex: "/profile-photo.jpg/" }) {
          childImageSharp {
            fixed(quality: 80) {
              src
            }
          }
        }
      }
    `
  )

  description = description || config.siteDescription
  image = image
    ? urljoin(config.siteUrl, image)
    : urljoin(config.siteUrl, profilePhoto.childImageSharp.fixed.src)
  title = title
    ? `${title} – ${config.siteTitle}`
    : `${config.siteTitle} – Trying to survive every single day`
  const url = slug ? urljoin(config.siteUrl, slug) : config.siteUrl

  // console.log('description,,', description)
  // console.log('image,,', image)
  // console.log('title,,', title)
  // console.log('url,,', url)
  return (
    <Helmet>
      <title>{title}</title>
      {/* General tags */}
      <meta name="description" content={description} />
      <meta name="image" content={image} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}
