import React from 'react'

export default function Navigation() {
  return (
    <footer className="footer container custom-link">
      <div>
        <span>
          {`Created with ❤️ by `}&nbsp;
          <a href="https://github.com/ohmpzz" rel="nofollow noreferrer">
            Ohm Piromrak
          </a>
        </span>
        <span className="copyright">© {new Date().getFullYear()}</span>
      </div>
    </footer>
  )
}
