import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

export default function Navigation({ menuLinks }) {
  const [scrolled, setScrolled] = useState(false)
  const navOnScroll = () => {
    if (window.scrollY > 20) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', navOnScroll)
    return () => {
      window.removeEventListener('scroll', navOnScroll)
    }
  }, [])

  return (
    <nav className={scrolled ? 'nav scroll' : 'nav'}>
      <div className="nav-container">
        <div className="brand">
          <Link to="/">
            <span role="img">{`❄️`}</span>
            <span className="text">Air Pawikorn</span>
          </Link>
        </div>
        <div className="links">
          {menuLinks.map(link => (
            <Link key={link.name} to={link.link} activeClassName="active">
              {link.name}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  )
}
