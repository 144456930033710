import React from 'react'
import Helmet from 'react-helmet'
import config from '../../data/SiteConfig'
import favicon from '../images/favicon.png'

import Footer from '../components/Footer'
import Navigation from '../components/Navigation'
import '../styles/main.scss'

export default function MainLayout({ children }) {
  return (
    <>
      <Helmet>
        <html lang="en-US" />
        <link rel="shortcut icon" type="image/png" href={favicon} />
        <meta name="description" content={config.siteDescription} />
      </Helmet>
      <Navigation menuLinks={config.menuLinks} />
      <main id="main-content">{children}</main>
      <Footer />
    </>
  )
}
